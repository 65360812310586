import { TextField } from "@material-ui/core"
import { graphql, Link, navigate } from "gatsby"
import { withTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import { SecondaryButton } from "../../components/Buttons"
import Container from "../../components/Container"
import LoginStateContext, {
  withLoginStateContext,
} from "../../contexes/LoginStateContext"
import { authenticate, loggedIn } from "../../services/moocfi"
import Layout from "../../templates/Layout"

const Footer = styled.div`
  margin-top: 1.4rem;
  display: grid;
  justify-content: center;
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  opacity: 0.9;
  padding: 0;
  opacity: 0.8;

  & ul {
    list-style: none;
    margin: 0 auto;
    padding: 0 !important;
    width: 500px;
  }

  & li {
    display: inline-block;
    padding: 0 !important;
    margin: 0;
  }

  & li:not(:last-child)::after {
    content: "||";
  }

  & a {
    padding: 0.75rem;
    color: #333;
  }
`

const Row = styled.div`
  margin-bottom: 1.5rem;
`

const Form = styled.form`
  button:last-child {
    margin-top: 1.4rem;
  }
`

const InfoBox = styled.div`
  margin-bottom: 3rem;
  font-size: 1rem;
  line-height: 1.8rem;
`

const FormContainer = styled.div`
  height: 100%;
  margin: 2rem auto;
  text-align: center;
  max-width: 600px;

  h1 {
    font-family: "Josefin Sans", sans-serif;
  }
`

class SignInPage extends React.Component {
  static contextType = LoginStateContext

  componentDidMount = () => {
    // This is a workaroud to some really weird login redirect problems
    this.fallbackRedirector = setInterval(() => {
      if (!loggedIn()) {
        return
      }
      setTimeout(() => {
        if (
          window.location.pathname === "/sign-in/" ||
          window.location.pathname === "/sign-in"
        ) {
          window.location = "/"
        }
      }, 2000)
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.fallbackRedirector)
  }

  onClick = async (e) => {
    e.preventDefault()
    if (
      this.state.submitting ||
      (this.state.email.length === 0 && this.state.password.length === 0)
    ) {
      return
    }
    this.setState({
      submitting: true,
      error: false,
    })
    try {
      await authenticate({
        username: this.state.email,
        password: this.state.password,
      })

      // Give loginstate time to update
      setTimeout(() => {
        try {
          if (typeof window !== "undefined") {
            window.history.go(-1)
            return
          }
          navigate("/")
        } catch (_e) {
          navigate("/")
        }
      }, 100)
    } catch (error) {
      this.setState({ error: true, submitting: false })
      return
    }
  }

  state = {
    email: "",
    password: "",
    submitting: false,
    error: false,
  }

  render() {
    if (this.context.loggedIn && !this.state.submitting) {
      navigate("/")
      return <div>Redirecting....</div>
    }
    return (
      <Layout>
        <Container>
          <Helmet title={this.props.t("common:login")} />
          <FormContainer>
            <h1>{this.props.t("common:login")}</h1>
            <Form>
              <InfoBox>
                {this.props.t("user:courseUses")}{" "}
                <a
                  href="https://mooc.fi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  mooc.fi
                </a>{" "}
                {this.props.t("user:courseUses2")}
              </InfoBox>

              <Row>
                <TextField
                  id="outlined-adornment-email"
                  variant="outlined"
                  type="text"
                  label={this.props.t("user:emailUsername")}
                  fullWidth
                  value={this.state.email}
                  onChange={(o) => this.setState({ email: o.target.value })}
                />
              </Row>
              <Row>
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  type={this.state.showPassword ? "text" : "password"}
                  label={this.props.t("user:password")}
                  fullWidth
                  value={this.state.password}
                  onChange={(o) => this.setState({ password: o.target.value })}
                />
              </Row>

              <SecondaryButton
                onClick={this.onClick}
                to="/profile"
                disabled={this.state.submitting}
                variant="contained"
                type="submit"
                color="#52CCA8"
                hover="#4EC4A1"
                active="#4CBF9D"
              >
                {this.props.t("common:login")}
              </SecondaryButton>
            </Form>
            {this.state.error && (
              <InfoBox>
                <b>{this.props.t("user:wrongDetails")}</b>
              </InfoBox>
            )}
            <Footer>
              <ul>
                <li>
                  <Link to="/sign-up">
                    {this.props.t("user:createAccount")}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://tmc.mooc.fi/password_reset_keys/new"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.t("user:forgottenPW")}
                  </a>
                </li>
              </ul>
            </Footer>
          </FormContainer>
        </Container>
      </Layout>
    )
  }
}

export default withTranslation(["common", "user"])(
  withLoginStateContext(SignInPage),
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
