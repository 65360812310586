import React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import styled from "styled-components"

const StyledLink = styled(Link)`
  margin: 1rem 0;

  &:hover {
    text-decoration: none;
  }
`

const StyledButton = styled.button`
  display: inline-block;
  width: auto;
  border-radius: 4px;
  padding: 14px 24px;
  color: #333;
  border: none;
  text-transform: none;
  outline: 0;
  background: #fff;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  backface-visibility: hidden;
  letter-spacing: normal;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  cursor: pointer;

  :hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.15), 0 0 5px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
`
export const Primary = styled(StyledButton)`
  padding: 16px 34px;
  background: ${(props) => (props.color ? props.color : "#f1f1f1")};
  color: ${(props) => (props.color ? "#fff" : "#f1f1f1")};
  font-weight: 500;
  border-radius: 50px;
  font-family: "Josefin Sans", sans-serif;
`
const Secondary = styled(StyledButton)`
  padding: 10px 22px;
  background: ${(props) => (props.color ? props.color : "#f1f1f1")};
  color: ${(props) => (props.color ? "#fff" : "#f1f1f1")};
  font-weight: 500;
  border-radius: 50px;
  font-family: "Josefin Sans", sans-serif;
  box-shadow: none;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;

  &:hover {
    transform: none;
    box-shadow: none;
    background: ${(props) => (props.hover ? props.hover : "#f1f1f1")};
  }

  &:active {
    transform: none;
    box-shadow: none;
    background: ${(props) => (props.active ? props.active : "#f1f1f1")};
  }
`
const Ghost = styled(StyledButton)`
  background: transparent;
`

export const PrimaryButton = ({
  children,
  to,
  onClick,
  disabled,
  color,
  variant = "outlined",
}) => (
  <StyledLink
    onClick={onClick}
    to={to === undefined ? false : to}
    disabled={disabled}
  >
    <Primary variant={variant} color={color}>
      {" "}
      {children}{" "}
    </Primary>
  </StyledLink>
)

export const SecondaryButton = ({
  children,
  to,
  onClick,
  disabled,
  color,
  hover,
  active,
  variant = "outlined",
}) => (
  <StyledLink
    onClick={onClick}
    to={to === undefined ? false : to}
    disabled={disabled}
  >
    <Secondary variant={variant} color={color} hover={hover} active={active}>
      {" "}
      {children}{" "}
    </Secondary>
  </StyledLink>
)

export const GhostButton = ({
  children,
  to,
  onClick,
  disabled,
  variant = "outlined",
}) => (
  <StyledLink
    onClick={onClick}
    to={to === undefined ? false : to}
    disabled={disabled}
  >
    <Ghost variant={variant}> {children} </Ghost>
  </StyledLink>
)

/* export default withSimpleErrorBoundary(Button) */
